import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';
import { SfFaceIdMIcon } from '@alfalab/icons-glyph/SfFaceIdMIcon';

import ServerErrorsNotificationNew from '#/src/components/ui/server-errors-notification/server-errors-notification-new';
import { useAppDispatch, useAppSelector, useIsomorphicLayoutEffect } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { getIsPincodeReady } from '#/src/lib/get-is-pincode-available';
import { getUserLocalInfo } from '#/src/lib/user-info';
import { Routes } from '#/src/models';
import { useRequestAkeyDeeplinkMutation } from '#/src/store/api/akey-api';
import { selectPincodeAttemptsLeft } from '#/src/store/pincode/selectors';
import {
    selectAkeyDeeplink,
    selectAkeyError,
    selectIsAKeyAvailable,
} from '#/src/store/redux/akey/selectors';
import { akeyDeeplinkSet, akeyErrorCleared } from '#/src/store/redux/akey/slice';
import {
    getQueryRedirectParams,
    isGlobalPreloaderVisible as setIsGlobalPreloaderVisible,
    selectAKeyRequestCount,
    selectAKeyTimeout,
} from '#/src/store/redux/app/selectors';
import { akeyStatusSet, preloaderShown, queryParamCleared } from '#/src/store/redux/app/slice';
import {
    selectCustomDesignSubtitle,
    selectCustomDesignTitle,
} from '#/src/store/redux/custom-design/selectors';

import { LogoAnimated } from '../logo-animated';

import './akey.css';

const cn = createCn('akey');

/**
 * Страница AKey.
 * Доступ по прямой ссылке запрещен к этой странице. Переход возможен только через редирект на фронте.
 */
const AKey: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const [requestAkeyDeeplink] = useRequestAkeyDeeplinkMutation();
    const attemptsLeft = useAppSelector(selectPincodeAttemptsLeft);
    const deeplink = useAppSelector(selectAkeyDeeplink);
    const error = useAppSelector(selectAkeyError);
    const isAKeyAvailable = useAppSelector(selectIsAKeyAvailable);
    const [xUserInfo, setXUserInfo] = useState<ReturnType<typeof getUserLocalInfo> | null>(null);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const customDesignTitle = useAppSelector(selectCustomDesignTitle);
    const customDesignSubtitle = useAppSelector(selectCustomDesignSubtitle);
    const isGlobalPreloaderVisible = useAppSelector(setIsGlobalPreloaderVisible);
    const isPincodeReady = useAppSelector(getIsPincodeReady);
    const akeyTimeout = useAppSelector(selectAKeyTimeout);
    const akeyRequestCount = useAppSelector(selectAKeyRequestCount);

    useIsomorphicLayoutEffect(() => {
        setXUserInfo(getUserLocalInfo());
    }, []);

    useEffect(() => {
        const requestInterval = setInterval(() => {
            requestAkeyDeeplink();
        }, akeyTimeout);

        setTimeout(() => {
            clearInterval(requestInterval);
        }, akeyTimeout * (akeyRequestCount - 1));

        return () => {
            if (requestInterval) {
                clearInterval(requestInterval);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleButtonClick = (route: string, clearState?: boolean) => {
        trackUserEvent('AKey Page', 'Click', 'Go to login page', queryRedirectParams.client_id);

        if (clearState) {
            dispatch(preloaderShown());
            dispatch(akeyDeeplinkSet(''));
            dispatch(akeyStatusSet(true));
            dispatch(queryParamCleared('pc_session_id'));
        }

        navigate(
            {
                pathname: route,
                search,
            },
            { state: { asSecondaryPage: true } },
        );
    };

    const title = () => {
        if (customDesignTitle) {
            return (
                <div className={cn('header')}>
                    <Typography.TitleMobile
                        rowLimit={2}
                        className={cn('title')}
                        view='medium'
                        font='styrene'
                        tag='h1'
                        dataTestId='merchant-title'
                    >
                        {customDesignTitle}
                    </Typography.TitleMobile>
                    <Typography.Text
                        rowLimit={2}
                        tag='div'
                        dataTestId='merchant-subtitle'
                        view='primary-large'
                    >
                        {customDesignSubtitle}
                    </Typography.Text>
                </div>
            );
        }

        if (xUserInfo?.name) {
            return (
                <div className={cn('header')}>
                    <Typography.TitleMobile
                        view='medium'
                        font='styrene'
                        tag='h1'
                        dataTestId='title'
                    >
                        Привет, {xUserInfo.name}!
                    </Typography.TitleMobile>
                    <Typography.Text
                        view='primary-medium'
                        color='secondary'
                        className={cn('text')}
                        tag='div'
                        dataTestId='subtitle'
                    >
                        Вам доступен быстрый вход
                        <br /> в&nbsp;Альфа-Онлайн
                    </Typography.Text>
                </div>
            );
        }

        return (
            <div className={cn('header')}>
                <Typography.TitleMobile view='medium' font='styrene' tag='h1' dataTestId='title'>
                    Добро пожаловать
                    <br />
                    в&nbsp;Альфа-Онлайн
                </Typography.TitleMobile>
                <Typography.Text
                    view='primary-medium'
                    color='secondary'
                    className={cn('text')}
                    tag='div'
                    dataTestId='subtitle'
                >
                    Вам доступен быстрый вход.
                </Typography.Text>
            </div>
        );
    };

    if (!isAKeyAvailable) return null;

    return (
        <div className={cn('container', { hidden: isGlobalPreloaderVisible })}>
            <div className={cn('content-top')}>
                <div className={cn('logo')} data-test-id='logo'>
                    <LogoAnimated isVisible={!isGlobalPreloaderVisible} />
                </div>
            </div>

            <div className={cn('divider')} />

            <div className={cn('content-bottom')}>
                {title()}

                {error && (
                    <ServerErrorsNotificationNew
                        errorMessage={error}
                        onClose={() => {
                            dispatch(akeyErrorCleared());
                        }}
                    />
                )}

                <div className={cn('buttons')}>
                    {isAKeyAvailable && deeplink && (
                        <Button
                            view='primary'
                            block={true}
                            target='_blank'
                            href={deeplink}
                            leftAddons={<SfFaceIdMIcon />}
                            dataTestId='deeplink-button'
                        >
                            Войти
                        </Button>
                    )}

                    {isPincodeReady && attemptsLeft ? (
                        <Button
                            className={cn('button')}
                            view='transparent'
                            block={true}
                            onClick={() => handleButtonClick(Routes.PINCODE)}
                            dataTestId='pincode-button'
                        >
                            Ввести секретный код
                        </Button>
                    ) : (
                        <Button
                            className={cn('button')}
                            view='transparent'
                            block={true}
                            onClick={() => handleButtonClick(Routes.PHONE_AUTH)}
                            dataTestId='phone-auth-button'
                        >
                            Войти по номеру телефона
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AKey;
