/* eslint-disable no-useless-concat */
const errorDictionary: Record<string, string> = {
    DEFAULT: 'Некорректные данные.\n Пожалуйста, попробуйте ещё раз.',
    SMS_DEFAULT: '',
    ERRCUR1041:
        'Мы обнаружили, что вы поменяли SIM-карту. Чтобы обезопасить ваши деньги, вход в Альфа-Мобайл' +
        ' временно заблокирован. Для восстановления доступа к приложению, пожалуйста, свяжитесь с нами:\n\n' +
        ' — Обратитесь с паспортом в любое отделение Альфа-Банка;\n\n' +
        ' — или позвоните +7 (495) 788-88-78 (Москва, МО, заграница),  8 800 200-00-00 (регионы)' +
        ' для прохождения идентификации по кодовому слову',
    ERRMNU6006:
        'Мы обнаружили, что вы поменяли SIM-карту. Чтобы обезопасить ваши деньги, вход в Альфа-Мобайл' +
        ' временно заблокирован. Для восстановления доступа к приложению, пожалуйста, свяжитесь с нами:\n\n' +
        ' — Обратитесь с паспортом в любое отделение Альфа-Банка;\n\n' +
        ' — или позвоните +7 (495) 788-88-78 (Москва, МО, заграница),  8 800 200-00-00 (регионы)' +
        ' для прохождения идентификации по кодовому слову',

    ERRCUR1025: 'Пароль устарел.\n Пожалуйста, запросите новый',
    ERRCUR1026: 'Пароль введён неверно',
    ERRCUR1012:
        'Превышено количество попыток ввода кода из СМС.\n Пожалуйста, запросите код повторно',
    ERRLGT1011:
        'Превышено количество попыток ввода кода из СМС.\n Пожалуйста, запросите код повторно',
    ERRMNU1002: 'Произошла ошибка.\n Пожалуйста, запросите пароль повторно',
    KSM2010: 'Некорректные данные.\n Пожалуйста, попробуйте ещё раз.',
    'PASSPORT.CARD_NUMBER_IDP.INVALID_3RD_PARTY_PHONE':
        'Некорректные данные.\n' +
        ' Проверьте данные карты и номер телефона. Если ошибка сохраняется, попробуйте подключить к карте Альфа-Чек.' +
        ' Это можно сделать через банкомат или попросить владельца карты подключить Альфа-Чек' +
        ' через Альфа-Мобайл или Альфа-Клик.',
    'PASSPORT.NOT_CLIENT_SMS_IDP.REGISTRATION_DISABLED':
        'Регистрация на фестиваль AFP закрыта. ' +
        'Для входа по номеру карты или счёта воспользуйтесь кнопкой "Войти".',
    'PASSPORT.PHONE_NUMBER_IDP.REGISTRATION_DISABLED':
        'Регистрация на фестиваль AFP закрыта. ' +
        'Для входа по номеру карты или счёта воспользуйтесь кнопкой "Войти".',
    ERRFPP2011: 'Указанный номер мобильного телефона не зарегистрирован в банке',
    ERRMNU6005:
        'У вас была заблокирована возможность повторной регистрации.' +
        ' Для восстановления доступа, пожалуйста, свяжитесь с нами:\n\n' +
        ' — Обратитесь с паспортом в любое отделение Альфа-Банка;\n\n' +
        ' — или позвоните +7 (495) 788-88-78 (Москва, МО, заграница), 8 800 200-00-00 (регионы)',
    ERRMNU7000:
        'Указанный номер мобильного телефона не зарегистрирован в банке.\n\n' +
        'Пожалуйста, укажите другой или обратитесь по телефонам ' +
        '+7 495 78-888-78 для Москвы и 8 800 200-00-00 для регионов',
    CLIENT_IS_DEMO: 'CLIENT_IS_DEMO',
    'PASSPORT.CARD_NUMBER_IDP.INVALID_CARD_STATUS':
        'Ваша карта закрыта или заблокирована. ' + 'Для входа используйте другую карту.',
    'Too many attempts': 'Превышено количество запросов СМС.\n Пожалуйста, повторите позже',
    USER_IS_BLOCKED: 'Пользователь заблокирован. Обратитесь в банк для разблокировки',
    USER_HAS_EXCEEDED_NUMBERS_OF_ATTEMPTS_TO_USE_AUTO_PASSWORD:
        'Превышено количество входов по временному паролю. ' +
        'Для получения нового зайдите по ссылке "Забыли пароль" или обратитесь в банк',
    USER_NOT_FOUND: 'Неверный логин или пароль',
    PASSING_WRONG_PASSWORD_AND_USER_HAVE_ONLY_ONE_LOGIN_ATTEMPT:
        'Неверный пароль. В случае еще одной неудачной ' +
        'попытки пользователь будет заблокирован',
    PASSING_WRONG_PASSWORD_AND_USER_DOES_NOT_HAVE_LOGIN_ATTEMPT:
        'Неверный пароль. Превышено количество попыток ' +
        'ввода пароля. Пользователь заблокирован. Обратитесь в банк для разблокировки',
    AUTO_PASSWORD_IS_EXPIRED:
        'Ваш временный пароль истек. Для получения нового зайдите по ссылке "Забыли пароль" ' +
        'или обратитесь в банк',
    SCAN_ERROR: 'Ошибка сканирования',
    PHONE_NOT_FOUND: 'По данному номеру телефона пользователь не найден',
    CARD_IS_NOT_READY: 'Ваша карта ещё не готова. Попробуйте позже',
    APP_VERSION_MUST_BE_ABOVE:
        'Версия приложения более не поддерживается. Обновите, пожалуйста, приложение',
    OS_OR_APP_VERSION_NOT_FOUND:
        'Версия приложения более не поддерживается. Обновите, пожалуйста, приложение',
    IP_RESTRICTION_ERROR: 'С вашего IP-адреса запрещен вход в систему',
    AUTH_ATEMPT_ERROR: 'Код можно будет повторно запросить через',
    IMSI_VALIDATION_FAILED: 'IMSI не прошла проверку',
    CODE_HAS_EPXIRED: 'Просрочен код',
    TOO_EARLY: 'Несвоевременный запрос кода от пользователя',
    CONTRACT_VIOLATION: '',
    CODE_IS_WRONG: 'Неправильный код',
    GO_THROUGH_AGAIN:
        'Был сбой и данные не сохранились. Введите заново номер карты или счёта, пожалуйста',
    GO_INVEST_AUTH_ERROR: 'Мы не смогли найти такой логин и/или пароль.',
    INVEST_AUTH_ERROR:
        'Мы не смогли найти такой логин и/или пароль. Попробуйте ещё раз или воспользуйтесь восстановлением',
    AKEY_AUTH_ERROR: 'Вход через AKEY недоступен, войдите другим способом.',
    USER_LESS_AGE_6:
        'Пока зайти не получится. Авторизоваться могут только держатели карты от 6 лет.',
    USER_LESS_AGE_14: 'Пока зайти не получится. Авторизоваться могут только клиенты от 14 лет.',
    INVALID_CARD_STATUS:
        'У вас нет открытых карт. Закажите себе Альфа-карту, чтобы пользоваться услугами Банка.',
    CARDS_FOR_DIFFERENT_OWNERS: 'Для входа воспользуйтесь меню ввода номера карты.',
    REQUEST_SMS_VERIFICATION_EXCEEDED_TITLE: 'Превышено количество попыток ввода пароля',
    REQUEST_SMS_VERIFICATION_EXCEEDED_DESCRIPTION: 'Пожалуйста, повторите позже',
    REQUEST_SMS_VERIFICATION_FATAL_ERROR_TITLE: 'Вход недоступен',
    PINCODE_VERIFICATION_IS_FAILED: 'Неверный пинкод',
    PINCODE_ACTIVATION_IS_FAILED: 'Ошибка авторизации через пинкод',
    IFRAME_TIMEOUT: 'Ошибка загрузки данных iframe: сервис не доступен',
} as const;

export default errorDictionary;
