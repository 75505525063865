import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';
import uuid from 'uuid';

import { AppDispatch } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { clientErrorLog } from '#/src/lib/client-logger';
import { validatePhoneNumber } from '#/src/lib/client-validation/registration';
import { clearFormatting } from '#/src/lib/formatters';
import { getOnlyDigits } from '#/src/lib/get-only-digits';
import { scanCard as scanCardNative } from '#/src/lib/passport-mobile-bridge';
import { redirectTo } from '#/src/lib/redirect';
import { syncWithFpScript } from '#/src/lib/sinc-fingerprint';
import { setCookie } from '#/src/lib/update-cookie';
import { CookiesName, RegistrationType, Routes, ServerErrors } from '#/src/models';
import { customFetchBaseQuery } from '#/src/store/base-query';
import { selectIsFingerPrintEnabled } from '#/src/store/redux/app/selectors';
import { setMultifactorResponse } from '#/src/store/redux/app/slice';
import {
    alternativeLoginIsFree,
    alternativeLoginSet,
    passwordUpdateRejected,
    registrationFormUpdated,
    registrationRequested,
    registrationRequestRejected,
    registrationRequestResolved,
    scanCardAction,
    scanCardRejected,
    scanCardResolve,
} from '#/src/store/redux/registration/slice';
import { ApplicationState } from '#/src/store/types';
import { isString } from '#/src/types/base-guards';
import {
    AlternativeLoginCredentials,
    CorpRecoveryCredentials,
    FullNameRegistrationCredentials,
    LoginInformationCredentials,
    RegistrationCredentials,
    RetailRecoveryCredentials,
} from '#/src/types/interfaces';
import { Endpoint, HttpMethod } from '#/src/utils';

export const registrationApi = createApi({
    reducerPath: 'registrationApi',
    baseQuery: customFetchBaseQuery(),
    endpoints: (build) => ({
        requestRegistration: build.mutation<any, void>({
            queryFn: async (_payload, queryApi, _extraOptions, fetchWithBQ) => {
                const { dispatch } = queryApi;
                const state = queryApi.getState() as ApplicationState;

                const { phone, card, account, type } = state.Registration;

                const phoneNumber = validatePhoneNumber(phone) === null ? phone : null;
                const body = {
                    phone: getOnlyDigits(phoneNumber),
                    card: getOnlyDigits(card),
                    accountNumber: clearFormatting(account),
                    type: clearFormatting(type),
                    queryRedirectParams: state.App.queryRedirectParams,
                };
                const errorHandler = (errors: any[]) => {
                    dispatch(registrationRequestRejected(errors));
                    if (
                        Array.isArray(errors) &&
                        errors.length &&
                        errors[0].id === ServerErrors.PHONE_REQUIRED
                    ) {
                        dispatch(registrationRequestResolved());
                        redirectTo(Routes.PHONE_LOGIN);
                    }
                };

                dispatch(registrationRequested());
                const result = await fetchWithBQ({
                    url: Endpoint.OID_REGISTRATION_CUSTOMER,
                    method: HttpMethod.POST,
                    body,
                });

                if (result.error) {
                    const errorResult = result.error.data as any;

                    errorHandler(errorResult.errors);

                    return { error: result.error };
                }

                const resultData = result.data as any;

                if (Array.isArray(resultData.errors)) {
                    errorHandler(resultData.errors);
                } else if (resultData.redirectUrl) {
                    trackUserEvent(
                        'Registration Request',
                        'Click',
                        'First Factor Auth Success',
                        body?.queryRedirectParams?.client_id,
                    );
                    dispatch(registrationRequestResolved());
                    setCookie(CookiesName.passportSessionId, uuid.v4(), {
                        domain: '.alfabank.ru',
                        path: '/',
                        secure: true,
                        sameSite: 'Lax',
                    });
                }

                return { data: resultData };
            },
        }),
        fastRegistrationRequest: build.mutation<any, RegistrationCredentials>({
            queryFn: async (body, queryApi, _extraOptions, fetchWithBQ) => {
                const { dispatch } = queryApi;

                const result = await fetchWithBQ({
                    url: Endpoint.FAST_REGISTRATION,
                    method: HttpMethod.POST,
                    body,
                });

                if (result.error) {
                    const errorResult = result.error as any;
                    const errors = errorResult?.data?.errors;

                    if (errors[0]?.id === 'CHANNEL_NOT_CONNECTED') {
                        redirectTo(Routes.PHONE_AUTH);

                        return { error: result.error };
                    }

                    redirectTo(Routes.ERROR);

                    return { error: result.error };
                }

                dispatch(registrationRequestResolved());

                return { data: result.data };
            },
        }),
        requestRecovery: build.mutation<any, CorpRecoveryCredentials>({
            queryFn: async (body, queryApi, _extraOptions, fetchWithBQ) => {
                const { dispatch } = queryApi;

                const result = await fetchWithBQ({
                    url: Endpoint.OID_REGISTRATION_UPDATE,
                    method: HttpMethod.POST,
                    body,
                });

                if (result.error) {
                    const errorResult = result.error.data as any;

                    dispatch(passwordUpdateRejected(errorResult.errors));

                    return { error: result.error };
                }

                return { data: result.data };
            },
        }),
        fullNameRegistrationRequest: build.mutation<any, FullNameRegistrationCredentials>({
            queryFn: async (body, queryApi, _extraOptions, fetchWithBQ) => {
                const { dispatch } = queryApi;

                const result = await fetchWithBQ({
                    url: Endpoint.FULL_NAME_REGISTRATION,
                    method: HttpMethod.POST,
                    body,
                });

                if (result.error) {
                    const errorResult = result.error.data as any;

                    dispatch(registrationRequestRejected(errorResult.errors));
                    redirectTo(Routes.ERROR);

                    return { error: result.error as FetchBaseQueryError };
                }

                dispatch(registrationRequestResolved());

                return { data: result.data };
            },
        }),
        getAlternativeLogin: build.mutation<any, AlternativeLoginCredentials>({
            queryFn: async (body, queryApi, _extraOptions, fetchWithBQ) => {
                const { dispatch } = queryApi;

                const result = await fetchWithBQ({
                    url: Endpoint.ALTERNATIVE_LOGIN_GET,
                    method: HttpMethod.POST,
                    body,
                });

                if (result.error) {
                    return { error: result.error };
                }

                const resultData = result.data as any;

                dispatch(alternativeLoginSet(resultData.alternativeLogin));
                dispatch(
                    setMultifactorResponse({
                        url: '',
                        params: resultData,
                    }),
                );

                return { data: result.data };
            },
        }),
        getLoginInformation: build.mutation<any, LoginInformationCredentials>({
            queryFn: async (body, queryApi, _extraOptions, fetchWithBQ) => {
                const { dispatch } = queryApi;

                const result = await fetchWithBQ({
                    url: Endpoint.ALTERNATIVE_LOGIN_DEFINE_IS_FREE,
                    method: HttpMethod.POST,
                    body,
                });

                if (result.error) {
                    const errorResult = result.error.data as any;
                    const err = new Error(`GetLoginInfo: ${errorResult.errors[0]}`);

                    await clientErrorLog(err);

                    return { error: result.error };
                }

                dispatch(alternativeLoginIsFree(result.data));

                return { data: result.data };
            },
        }),
        updateAlternativeLoginOrPass: build.mutation<any, RetailRecoveryCredentials>({
            queryFn: async (body, queryApi, _extraOptions, fetchWithBQ) => {
                const { dispatch } = queryApi;

                dispatch(registrationRequested());

                const result = await fetchWithBQ({
                    url: Endpoint.OID_REGISTRATION_RETAIL_PASSWORD_UPDATE,
                    method: HttpMethod.POST,
                    body,
                });

                if (result.error) {
                    const errorResult = result.error.data as any;

                    dispatch(registrationRequestRejected(errorResult.errors));
                    dispatch(passwordUpdateRejected(errorResult.errors));
                    redirectTo(Routes.CARD_ACCOUNT);

                    return { error: result.error as FetchBaseQueryError };
                }

                dispatch(registrationRequestResolved());

                return { data: result.data };
            },
        }),
        scanCard: build.mutation<any, void>({
            queryFn: async (_payload, queryApi) => {
                const { dispatch } = queryApi;

                dispatch(scanCardAction());

                scanCardNative()
                    .then((card?: string) => {
                        dispatch(registrationFormUpdated({ card }));
                        dispatch(
                            scanCardResolve({
                                card,
                                type: RegistrationType.Card,
                            }),
                        );

                        return { data: card };
                    })
                    .catch((error: any) => {
                        dispatch(scanCardRejected(error));

                        return { error };
                    });

                return { data: 'scanCard' };
            },
        }),
        requestPhoneRegistration: build.mutation<any, void>({
            queryFn: async (_payload, queryApi, _extraOptions, fetchWithBQ) => {
                const { dispatch } = queryApi;
                const state = queryApi.getState() as ApplicationState;

                const errorHandler = (errors: any[], dispatch: AppDispatch) => {
                    if (Array.isArray(errors) && errors.length) {
                        switch (errors[0].id) {
                            case ServerErrors.NOT_PHYSICAL_PERSON:
                            case ServerErrors.MORE_THEN_ONE_USER_FOUND:
                            case ServerErrors.ID_TYPE_NOT_FOUND:
                            case ServerErrors.USER_IS_CLIENT:
                                dispatch(registrationRequestResolved());

                                redirectTo(Routes.CARD_ACCOUNT);

                                break;
                            default:
                                dispatch(registrationRequestRejected(errors));
                        }
                    } else {
                        dispatch(registrationRequestRejected(errors));
                    }
                };

                dispatch(registrationRequested());

                const isFingerPrintEnabled = selectIsFingerPrintEnabled(state);

                if (isFingerPrintEnabled) {
                    await syncWithFpScript(false, dispatch);
                }

                const body = {
                    queryRedirectParams: state.App.queryRedirectParams,
                    phone: getOnlyDigits(state.Registration.phone),
                };

                const result = await fetchWithBQ({
                    url: Endpoint.OID_REGISTRATION_BY_PHONE,
                    method: HttpMethod.POST,
                    body,
                });

                if (result.error) {
                    const errorResult = result.error.data as any;

                    errorHandler(errorResult.errors, dispatch);

                    return { error: result.error as FetchBaseQueryError };
                }

                const resultData = result.data as any;

                if (Array.isArray(resultData.errors)) {
                    errorHandler(resultData.errors, dispatch);
                } else if (isString(resultData.redirectUrl)) {
                    // очищаем форму
                    dispatch(registrationRequestResolved());
                }

                return { data: resultData };
            },
        }),
    }),
});

export const {
    useRequestRegistrationMutation,
    useFastRegistrationRequestMutation,
    useRequestRecoveryMutation,
    useFullNameRegistrationRequestMutation,
    useGetAlternativeLoginMutation,
    useGetLoginInformationMutation,
    useUpdateAlternativeLoginOrPassMutation,
    useScanCardMutation,
    useRequestPhoneRegistrationMutation,
} = registrationApi;
