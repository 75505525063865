import { onLCP, onFCP, onINP, onCLS, onTTFB } from 'web-vitals';

import { PartialMetric } from '../../models/prometheus';
import { sendMetrics } from './send-metrics';
import { getResourcesSize, getResources, getHostResources, byFormat } from './utils';

export function collectMetrics(contextRoot: string) {
    // Некоторые метрики могут быть собраны несколько раз, поэтому мы просто храним их в объекте пока не соберем все
    const metricsQueue: Set<PartialMetric> = new Set();

    try {
        // Подписываемся на события web-vitals. Многие из них могут происходить несколько раз, поэтому просто копим их
        onLCP((metric) => metricsQueue.add(metric));
        onFCP((metric) => metricsQueue.add(metric));
        onINP((metric) => metricsQueue.add(metric));
        onCLS((metric) => metricsQueue.add(metric));
        onTTFB((metric) => metricsQueue.add(metric));
    } catch (e) {
        console.log('[web-vitals] error: ', e);
    }

    let observer: undefined | PerformanceObserver;
    try {
        observer = new PerformanceObserver((list) => {
            list.getEntriesByType('navigation').forEach((entry) => {
                const entryNavigation = entry as PerformanceNavigationTiming;

                metricsQueue.add({ name: 'domInteractive', value: entryNavigation.domInteractive });
                metricsQueue.add({ name: 'domContentLoadedEventEnd', value: entryNavigation.domContentLoadedEventEnd });
                metricsQueue.add({ name: 'domComplete', value: entryNavigation.domComplete });
                metricsQueue.add({ name: 'responseEnd', value: entryNavigation.responseEnd });
            });
        });

        observer.observe({ type: 'navigation', buffered: true });

        window.addEventListener('load', () => {
            setTimeout(() => {
                try {
                    const cssResourcesSize = getResourcesSize(
                        getResources().filter(getHostResources).filter(byFormat('css')),
                    );
                    const jsResourcesSize = getResourcesSize(
                        getResources().filter(getHostResources).filter(byFormat('js')),
                    );
    
                    if (cssResourcesSize) {
                        metricsQueue.add({ name: 'cssResourcesSize', value: cssResourcesSize });
                    }
    
                    if (jsResourcesSize) {
                        metricsQueue.add({ name: 'jsResourcesSize', value: jsResourcesSize });
                    }
    
                    observer?.disconnect();
                } catch (error) {
                    console.log('[collectTimingData] error: ', error);
                }
            }, 0);
        });
    } catch (e) {
        console.log('[collectTimingData] error: ', e);
    }

    // Метрики мы будем отправлять только тогда, когда страница станет не активной. Это событие так же отлавливает закрытие вкладки
    window.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'hidden') {
            sendMetrics({ metricsQueue, contextRoot });
            metricsQueue.clear();
        }
    });

    // Safari не всегда корректно отлавливает событие `visibilitychange` при закрытии вкладки, поэтому мы так же отлавливаем `pagehide`
    window.addEventListener('pagehide', () => {
        sendMetrics({ metricsQueue, contextRoot });
        metricsQueue.clear();
    });
}

