export async function webauthnAuthorization(
    params: any,
    responseFunction: (res: any) => void,
    rejectFunction: (error: any) => void,
) {
    await navigator.credentials
        .get({
            publicKey: {
                challenge: params.challenge,
                timeout: params.timeout,
                rpId: params.rpId,
                allowCredentials: params.allowCredentials || [],
            },
        })
        .then((res) => {
            responseFunction(res);
        })
        .catch((error) => {
            rejectFunction(error);
        });
}
