import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { useAppSelector, useIsomorphicLayoutEffect } from '#/src/hooks';
import { sendMetrics } from '#/src/lib/analitycs';
import { getMetricsData } from '#/src/lib/get-metrics-data';
import { getIsPincodeAvailable } from '#/src/lib/get-is-pincode-available';
import { getAlfaMetricsScript } from '#/src/server/scripts/alfa-metrica';
import { selectIsAKeyAvailable } from '#/src/store/redux/akey/selectors';
import {
    getQueryRedirectParams,
    selectIsMobile,
    selectMetricsConfig,
    selectMetricsDebugEnabled,
} from '#/src/store/redux/app/selectors';
import { getWebAuthnFlag } from '#/src/store/redux/webauthn/selectors';

export const useCollectMetrics = () => {
    const [firstPage, setFirstPage] = useState<boolean>(true);
    const { pathname } = useLocation();
    const metricsConfig = useAppSelector(selectMetricsConfig);
    const isMobile = useAppSelector(selectIsMobile);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const metricsData = getMetricsData(queryRedirectParams, isMobile);
    const isMetricsDebugEnabled = useAppSelector(selectMetricsDebugEnabled);

    const isAKeyAvailable = useAppSelector(selectIsAKeyAvailable);
    const isWebAuthn = getWebAuthnFlag();
    const isPincodeAvailable = useAppSelector(getIsPincodeAvailable);

    useIsomorphicLayoutEffect(() => {
        getAlfaMetricsScript(metricsConfig);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        sendMetrics('Screen', 'Screen View', metricsData, isMetricsDebugEnabled);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (firstPage) {
            const loadingMetricsData = metricsData;

            loadingMetricsData.availableFactors = `${isAKeyAvailable ? 'akey, ' : ''}
            ${isWebAuthn ? 'webauthn, ' : ''}
            ${isPincodeAvailable ? 'pincode, ' : ''}`;

            sendMetrics(
                'Project loading passport',
                'Open',
                loadingMetricsData,
                isMetricsDebugEnabled,
            );
            setFirstPage(false);
        } else {
            sendMetrics('Screen', 'Screen View', metricsData, isMetricsDebugEnabled);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
};
