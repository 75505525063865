import { ClientIds, MetricsClientId, MetricsRoute } from '../models';

import getPathnameEndpoint from './get-pathname-endpoint';

export function trackUserEvent(
    event_category: string,
    event_action: string,
    event_label: string,
    client_id: string,
    event_value = '',
) {
    const pathname = getPathnameEndpoint(window.location.pathname);
    const routeDescription = MetricsRoute[pathname];

    if (window.sp && client_id !== ClientIds.investmentsMobile) {
        const clientId = MetricsClientId[client_id] ? MetricsClientId[client_id] : 'OTHER';

        window.sp('trackStructEvent', event_category, event_action, event_label, null, null, {
            schema: 'iglu:com.alfabank/custom_dimension/jsonschema/1-0-0',
            data: {
                1: clientId,
                2: routeDescription,
                3: event_value,
            },
        });
    }
}

export function sendMetrics(
    eventLabel: string,
    eventAction: string,
    dimensions: {
        deviceType?: string,
        path?: string,
        clientId?: string,
        availableFactors?: string,
        acrValues?: string,
        landingPage?: string,
        browserId?: string | null,
        platform?: string,
        status?: string,
        error?: string,
        traceId?: string,
    },
    isMetricsDebugEnabled: boolean,
) {
    if (window.sp) {
        if (isMetricsDebugEnabled) {
            console.log('sendMetrics', {eventAction, eventLabel, dimensions});
        }

        window.sp('trackStructEvent', 'Passport page', eventAction, eventLabel, null, null, {
            schema: 'iglu:com.alfabank/custom_dimension/jsonschema/1-0-0',
            data: {
                1: dimensions.deviceType,
                2: dimensions.path,
                3: dimensions.clientId,
                4: dimensions.availableFactors,
                6: dimensions.acrValues,
                7: dimensions.landingPage,
                8: dimensions.browserId,
                9: dimensions.traceId,
                10: dimensions.platform,
                20: dimensions.status,
                21: dimensions.error,

            },
        });
    }
}
