import React, { SVGProps } from 'react';
import { ContentState } from '@alfa-bank/newclick-composite-components/content-state';
import { createCn } from 'bem-react-classname';

import './error-block.css';

type Props = {
    title?: string;
    text: string;
    buttonText?: string;
    icon?: React.FC<SVGProps<SVGSVGElement>>;
    onClick?: () => void;
};

const cn = createCn('error-block');

export const ErrorBlock: React.FC<Props> = ({ title, text, buttonText, icon, onClick }) => (
    <ContentState
        className={`${cn('error')} ${cn('state')}`}
        title={title}
        text={text}
        layout='m'
        buttonText={buttonText}
        Icon={icon}
        onButtonClick={onClick}
    />
);