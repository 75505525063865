import { useLocation } from 'react-router';

import { useAppDispatch, useAppSelector, useIsomorphicLayoutEffect } from '#/src/hooks';
import { checkBrowserSecret } from '#/src/lib/check-browser-secret';
import { clientErrorLog } from '#/src/lib/client-logger';
import { getIsPincodeAvailable } from '#/src/lib/get-is-pincode-available';
import { redirectTo } from '#/src/lib/redirect';
import { Routes } from '#/src/models';
import {
    useGetAkeyDeeplinkMutation,
    useRequestAkeyDeeplinkMutation,
} from '#/src/store/api/akey-api';
import { usePincodeActivateMutation } from '#/src/store/pincode/api';
import {
    selectHasPincodeActivateData,
    selectIsPincodeVisited,
} from '#/src/store/pincode/selectors';
import { pincodeUpdated, pincodeVisitedSet } from '#/src/store/pincode/slice';
import { selectAkeyDeeplink, selectIsAKeyAvailable } from '#/src/store/redux/akey/selectors';
import {
    getQueryRedirectParams,
    selectBrowserSecretPublicKey,
    selectIsAKeyVisited,
    selectIsBrowserSecretAvailable,
    selectIsWebAuthnRegEnabled,
} from '#/src/store/redux/app/selectors';
import { isAkeyVisitedSet, preloaderHidden } from '#/src/store/redux/app/slice';
import { selectIsWebauthnAvailable } from '#/src/store/redux/webauthn/selectors';

import { FixedCacheKeys } from '../store/constants';

export const useAuthFactorCheck = () => {
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const [getDeeplink] = useGetAkeyDeeplinkMutation();
    const [requestDeeplink] = useRequestAkeyDeeplinkMutation();
    const [requestPincodeActivate] = usePincodeActivateMutation({
        fixedCacheKey: FixedCacheKeys.pincodeActivate,
    });

    const queryRedirectParams = useAppSelector(getQueryRedirectParams);

    const isAKeyAvailable = useAppSelector(selectIsAKeyAvailable);
    const isAKeyVisited = useAppSelector(selectIsAKeyVisited);
    const deeplink = useAppSelector(selectAkeyDeeplink);

    const isWebauthnAvailable = useAppSelector(selectIsWebauthnAvailable);
    const isWebAuthnRegEnabled = useAppSelector(selectIsWebAuthnRegEnabled);

    const isBrowserSecretAvailable = useAppSelector(selectIsBrowserSecretAvailable);
    const browserSecretPublicKey = useAppSelector(selectBrowserSecretPublicKey);

    const isPincodeAvailable = useAppSelector(getIsPincodeAvailable);
    const isPincodeVisited = useAppSelector(selectIsPincodeVisited);
    const hasPincodeActivateData = useAppSelector(selectHasPincodeActivateData);

    const setBrowserSecrets: () => Promise<void> = async () => {
        if (isBrowserSecretAvailable) {
            const newBrowserSecretData = await checkBrowserSecret(browserSecretPublicKey);

            if (newBrowserSecretData) {
                dispatch(pincodeUpdated(newBrowserSecretData));
            }
        }
    };

    useIsomorphicLayoutEffect(() => {
        (async () => {
            switch (pathname) {
                case Routes.PHONE_AUTH:
                case Routes.LOGIN:
                case Routes.CARD_ACCOUNT:
                    await setBrowserSecrets();

                    if (isAKeyAvailable && !isAKeyVisited) {
                        const result = await requestDeeplink();

                        if (result.data?.app_start_deeplink) {
                            redirectTo(Routes.AKEY);
                            break;
                        }
                    }

                    if (isWebauthnAvailable) {
                        redirectTo(Routes.WEBAUTHN);
                        break;
                    }

                    if (isPincodeAvailable && !isPincodeVisited) {
                        const response = await requestPincodeActivate();

                        if (response.data?.attemptsLeft) {
                            redirectTo(Routes.PINCODE);
                            break;
                        }
                    }

                    dispatch(preloaderHidden());
                    break;

                case Routes.AKEY:
                    if (!isAKeyAvailable) {
                        redirectTo(Routes.PHONE_AUTH);
                        break;
                    }

                    await setBrowserSecrets();

                    dispatch(isAkeyVisitedSet(true));

                    if (!queryRedirectParams.pc_session_id && !deeplink) {
                        await requestDeeplink();
                    }

                    if (!hasPincodeActivateData && isPincodeAvailable) {
                        try {
                            await requestPincodeActivate();
                        } catch (error) {
                            const err = new Error(`Pincode activate error: ${error}`);

                            clientErrorLog(err);
                        }
                    }

                    if (!queryRedirectParams.pc_session_id) {
                        dispatch(preloaderHidden());
                    }
                    break;

                case Routes.WEBAUTHN:
                    if (!isWebauthnAvailable) {
                        redirectTo(Routes.PHONE_AUTH);
                        break;
                    }

                    await setBrowserSecrets();

                    if (!hasPincodeActivateData && isPincodeAvailable) {
                        await requestPincodeActivate();
                    }

                    dispatch(preloaderHidden());
                    break;

                case Routes.PINCODE:
                    if (!isPincodeAvailable || isPincodeVisited) {
                        redirectTo(Routes.PHONE_AUTH);
                        break;
                    }

                    if (isAKeyAvailable && !isAKeyVisited) {
                        redirectTo(Routes.AKEY);
                        break;
                    }

                    if (isWebauthnAvailable) {
                        redirectTo(Routes.WEBAUTHN);
                        break;
                    }

                    if (!hasPincodeActivateData) {
                        try {
                            const response = await requestPincodeActivate();

                            if (response && isAKeyAvailable) {
                                await getDeeplink();
                            }
                        } catch (error) {
                            const err = new Error(`Pincode activate error: ${error}`);

                            await clientErrorLog(err);
                        }

                        dispatch(pincodeVisitedSet(true));
                    }
                    dispatch(preloaderHidden());
                    break;

                case Routes.JWT_AUTH:
                    await setBrowserSecrets();

                    dispatch(preloaderHidden());
                    break;

                case Routes.WEBAUTHN_REG:
                    if (!isWebAuthnRegEnabled || !isWebauthnAvailable) {
                        redirectTo(Routes.PHONE_AUTH);
                        break;
                    }

                    dispatch(preloaderHidden());
                    break;

                default:
                    dispatch(preloaderHidden());
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
};
