import React, { FC } from 'react';

import { Gap } from '@alfalab/core-components/gap';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { PureCell } from '@alfalab/core-components/pure-cell';
import { Typography } from '@alfalab/core-components/typography';
import PhoneMIcon from '@alfalab/icons-glyph/PhoneMIcon';

import { useAppSelector } from '#/src/hooks';
import { openCallScreenNative } from '#/src/lib/passport-mobile-bridge';
import { selectGetDeviceOSName } from '#/src/store/redux/app/selectors';

const NativeContactCell: FC = () => {
    const deviceOS = useAppSelector(selectGetDeviceOSName);

    const handleClick = () => {
        if (deviceOS === 'Android') {
            openCallScreenNative();
        } else {
            // TODO: https://jira.moscow.alfaintra.net/browse/CHATNORRIS-3064
            // window.location.assign('alfabank:///dashboard/universal_support_call');
        }
    };

    return (
        <React.Fragment>
            <PureCell direction='horizontal' verticalPadding='default' onClick={handleClick}>
                <PureCell.Graphics>
                    <SuperEllipse
                        size={48}
                        backgroundColor='var(--color-light-neutral-translucent-100)'
                    >
                        <PhoneMIcon color='#0CC44D' />
                    </SuperEllipse>
                </PureCell.Graphics>
                <PureCell.Content>
                    <PureCell.Main>
                        <Typography.Text view='primary-medium' weight='medium' color='primary'>
                            Позвонить в банк
                        </Typography.Text>

                        <PureCell.Text titleColor='secondary' view='primary-small'>
                            По интернету
                        </PureCell.Text>
                    </PureCell.Main>
                </PureCell.Content>
            </PureCell>
            <Gap size='m' direction='vertical' />
            <Typography.TitleMobile view='xsmall' tag='div'>
                Другие контакты банка
            </Typography.TitleMobile>
            <Gap size='xs' direction='vertical' />
        </React.Fragment>
    );
};

export default NativeContactCell;
