import { selectIsPincodeAvailable, selectIsPincodeVisited } from '#/src/store/pincode/selectors';
import { ApplicationState } from '#/src/store/types';

import { LocalStorageName } from '../models';

const getBrowserSecret: () => boolean = () => {
    // Скрываем на время SSR
    if (typeof window === 'undefined') return false;

    return Boolean(
        window?.localStorage?.getItem(LocalStorageName.browserSecret) &&
            window.localStorage?.getItem(LocalStorageName.browserSecretDate),
    );
};

export const getIsPincodeReady = (state: ApplicationState) => {
    const isPincodeReady = selectIsPincodeAvailable(state);
    const browserSecret = getBrowserSecret();

    return isPincodeReady && browserSecret;
};

export const getIsPincodeAvailable = (state: ApplicationState) => {
    const isPincodeAvailable = selectIsPincodeAvailable(state);
    const isPincodeVisited = selectIsPincodeVisited(state);
    const browserSecret = getBrowserSecret();

    return isPincodeAvailable && browserSecret && !isPincodeVisited;
};
